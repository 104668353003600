import { FeatureData } from '@innedit/innedit';
import { PaiementType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../../../../components/List/Item/Paiement';
import CMSView from '../../../../../components/View';
import List from '../../../../../containers/Espace/List';
import paiement from '../../../../../params/paiement.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PaiementsPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          filters={compact([
            {
              libelle: 'Status',
              name: 'status',
              options: [
                {
                  label: 'Annulé',
                  value: 'canceled',
                },
                {
                  label: 'Partiellement remboursé',
                  value: 'partial_refunded',
                },
                {
                  label: 'Remboursé',
                  value: 'refunded',
                },
                {
                  label: 'Capture requis',
                  value: 'requires_capture',
                },
                {
                  label: 'Méthode de paiement requis',
                  value: 'requires_payment_method',
                },
                {
                  label: 'Validé',
                  value: 'succeeded',
                },
              ],
            },
          ])}
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/gestion/paiements/`}
          model={
            new FeatureData<PaiementType>({
              espaceId,
              collectionName: 'paiements',
              params: paiement,
            })
          }
          removeAddButton
          search={location.search}
          title={t('paiements.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PaiementsPage);
