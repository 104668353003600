import { DocumentType, PaiementType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import ListItemDate from '../../ListItemDate';
import Tag, { TagStateProps } from '../../Tag';
import Item from './index';

const labelPaymentStatus = (paymentStatus?: string): string | undefined => {
  let statusLabel;
  switch (paymentStatus) {
    case 'canceled': {
      statusLabel = 'Paiement annulé';
      break;
    }

    case 'partial_refunded': {
      statusLabel = 'Paiement partiellement remboursé';
      break;
    }

    case 'refunded': {
      statusLabel = 'Paiement remboursé';
      break;
    }

    case 'requires_capture': {
      statusLabel = 'En attente de validation';
      break;
    }

    case 'requires_payment_method': {
      statusLabel = "En attente d'un moyen de paiement";
      break;
    }

    case 'succeeded': {
      statusLabel = 'Paiement validé';
      break;
    }

    default:
      statusLabel = paymentStatus;
  }

  return statusLabel;
};

const statePaymentStatus = (paymentStatus?: string): TagStateProps => {
  let state: TagStateProps;
  switch (paymentStatus) {
    case 'canceled': {
      state = 'info';
      break;
    }

    case 'partial_refunded': {
      state = 'warning';
      break;
    }

    case 'refunded': {
      state = 'info';
      break;
    }

    case 'requires_capture': {
      state = 'danger';
      break;
    }

    case 'requires_payment_method': {
      state = 'warning';
      break;
    }

    case 'succeeded': {
      state = 'success';
      break;
    }

    default:
      state = 'standard';
  }

  return state;
};

const ListItemPaiement: FC<ListItemProps<PaiementType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<PaiementType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <Item doc={doc} index={index} onClick={onClick}>
      <div className="flex flex-1 space-x-6 items-center">
        <ListItemDate date={doc.createdAt} />

        <div className="flex-1">
          <Tag state={statePaymentStatus(doc.status)}>
            {labelPaymentStatus(doc.status)}
          </Tag>
        </div>

        <div className="flex space-x-2">
          {(undefined === doc.amountReceived || 0 === doc.amountReceived) && (
            <span>{doc.amount} €</span>
          )}
          {undefined !== doc.amountRefunded && doc.amountRefunded > 0 && (
            <span>{doc.amountReceived - doc.amountRefunded} €</span>
          )}
          {undefined !== doc.amountReceived && doc.amountReceived > 0 && (
            <span
              className={classnames({
                'line-through':
                  undefined !== doc.amountRefunded && doc.amountRefunded > 0,
              })}
            >
              {doc.amountReceived} €
            </span>
          )}
        </div>
      </div>
    </Item>
  );
};

export default ListItemPaiement;
